<template>
<v-container v-if="progress" fluid>
  <v-row class="pa-3 mt-n3">
    <date-range-picker ref="picker" opens="left" control-container-class="ml-3" :locale-data="{ 
          firstDay: 1,
          format: 'yyyy-mm-dd',
          applyLabel: '确定',
          cancelLabel: '取消',
          daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
          monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        }" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="{startDate: progress.pr_start_date, endDate: progress.pr_end_date }" :ranges="false" @update="changeDate" :append-to-body="true">
      <template v-slot:input="picker" >
        <div>
          <v-icon color="blue">mdi-calendar</v-icon>
        </div>
      </template>
    </date-range-picker>
    <editor-content @input="inputText"></editor-content>
  </v-row>
  <v-row class="mx-0 mt-n3 pa-2 grey lighten-2 d-flex">
    <!-- file upload -->
    <v-btn small color="grey" text @click="openFiles"><v-icon>mdi-paperclip</v-icon>附件</v-btn>
    <input ref="fileUpload" style="display:none;" type="file" @change="onFileChanged">
    <v-spacer></v-spacer>
    <v-btn :disabled="progress.pr_description==''" @click="updateProgress" color="primary" small>发布</v-btn>
  </v-row>
  <v-row class="pa-3">
    <v-chip class="my-1 mx-1" @click:close="files.splice(i, 1)" outlined close label color="cyan" v-for="(file, i) in files">{{ file.name }}</v-chip>
  </v-row>
</v-container>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ProgressForm',
  props: ['progress'],
  components: {
    DateRangePicker,
    EditorContent: () => import('@/components/okrgoal/rightInfo/editorContent/EditorContent.vue'),
  },
  data() {
    return {
      files: [],
    }
  },
  methods: {
    ...mapActions('fileUpload', ['filesUpload']),
    changeDate(e) {
      let start = e.startDate;
      let end = e.endDate;
      start = new Date(start);
      end = new Date(end);
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      this.progress.pr_start_date = start;
      this.progress.pr_end_date = end;
    },
    updateProgress() {
      if(this.files.length > 0) {
        let formData = new FormData()
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i]
          formData.append('files', file)
        }
        this.filesUpload(formData).then(res => {
          let fileIds = []
          res.map(file => {
            fileIds.push(file.file_id)
          })
          this.$emit('update', fileIds.join());
        })
      } else {
        this.$emit('update');
      }      
    },
    inputText(e) {
      this.progress.pr_description = e.prgValue;
    },
    openFiles() {
      this.$refs.fileUpload.click();
    },
    onFileChanged(e) {
      this.files.push(e.target.files[0]);
    }
  },
}
</script>

