var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.progress)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-3 mt-n3"},[_c('date-range-picker',{ref:"picker",attrs:{"opens":"left","control-container-class":"ml-3","locale-data":{ 
          firstDay: 1,
          format: 'yyyy-mm-dd',
          applyLabel: '确定',
          cancelLabel: '取消',
          daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
          monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },"minDate":"2018-01-01","maxDate":"2050-12-31","dateRange":{startDate: _vm.progress.pr_start_date, endDate: _vm.progress.pr_end_date },"ranges":false,"append-to-body":true},on:{"update":_vm.changeDate},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('div',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-calendar")])],1)]}}],null,false,2288365191)}),_c('editor-content',{on:{"input":_vm.inputText}})],1),_c('v-row',{staticClass:"mx-0 mt-n3 pa-2 grey lighten-2 d-flex"},[_c('v-btn',{attrs:{"small":"","color":"grey","text":""},on:{"click":_vm.openFiles}},[_c('v-icon',[_vm._v("mdi-paperclip")]),_vm._v("附件")],1),_c('input',{ref:"fileUpload",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onFileChanged}}),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.progress.pr_description=='',"color":"primary","small":""},on:{"click":_vm.updateProgress}},[_vm._v("发布")])],1),_c('v-row',{staticClass:"pa-3"},_vm._l((_vm.files),function(file,i){return _c('v-chip',{staticClass:"my-1 mx-1",attrs:{"outlined":"","close":"","label":"","color":"cyan"},on:{"click:close":function($event){return _vm.files.splice(i, 1)}}},[_vm._v(_vm._s(file.name))])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }